import { ActionReducer, MetaReducer } from '@ngrx/store';
import { Keys, localStorageSync } from 'ngrx-store-localstorage';
import { INTAKE_SURVEY_FEATURE_KEY, IntakeSurveyState } from '@features/intake-survey/store';
import { TEMP_REGISTER_FEATURE_KEY, TempRegistrationState } from '@features/temp-registration/store';
/* eslint-disable @typescript-eslint/no-explicit-any */

type KeysOf<S> = (keyof S)[];

const keys: Keys = [
  { [INTAKE_SURVEY_FEATURE_KEY]: ['initialInfo', 'familyInfo'] as KeysOf<IntakeSurveyState> },
  { [TEMP_REGISTER_FEATURE_KEY]: ['form'] as KeysOf<TempRegistrationState> },
];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys, rehydrate: true })(reducer);
}

export const metaReducers: Array<MetaReducer> = [localStorageSyncReducer];
